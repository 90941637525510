import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';

class SelectTask extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      task: props.task,
      isLoading: true,
    };
  }

  componentDidMount() {
  }

  renderSelectTask() {
    const account = this.state.account;
    const task = this.state.task;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        task: value,
      });
      if (value) {
        document.getElementById('task_id').value = value.id;
      } else {
        document.getElementById('task_id').value = null;
      }
    };

    const loadOptions = (value) => {
      return fetch(`/accounts/${account.id}/tasks/select?search=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      )
      .then(response => response.json());
    };

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder={"Select a task..."}
        value={task}
        getOptionLabel={c => c.title}
        getOptionValue={c => c.id}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isClearable
        styles={{
          valueContainer: (base) => ({ ...base, minHeight: '40px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectTask()}
      </div>
    );
  }
}

SelectTask.propTypes = {
  account: PropTypes.object,
  task: PropTypes.object,
};

export default SelectTask;
