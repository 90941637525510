import React from "react"
import PropTypes from "prop-types"
import Select from 'react-select';
import _ from 'lodash';

class SelectFormAttribute extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      form_attributes: props.form_attributes,
      form_attribute_id: props.form_attribute_id,
      form_attribute_name: props.form_attribute_name,
    };
  }

  componentDidMount() {
  }

  renderSelectFormAttribute() {
    const form_attributes = this.state.form_attributes;
    const form_attribute_id = this.state.form_attribute_id;
    const form_attribute_name = this.state.form_attribute_name;
    const form_attribute = form_attribute_id ? _.find(form_attributes, {"id": form_attribute_id}) : null;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        form_attribute: value,
      });
      if (value) {
        document.getElementById(form_attribute_name).value = value.id;
      } else {
        document.getElementById(form_attribute_name).value = null;
      }
    };

    return (
      <Select
        options={form_attributes}
        name={form_attribute_name}
        placeholder="Select an attribute..."
        defaultValue={form_attribute}
        getOptionLabel={a => `${a.display_sequence} ${a.name}`}
        getOptionValue={a => a.id}
        isClearable
        isSearchable
        onInputChange={handleInputChange}
        onChange={handleChange}
        styles={{
          valueContainer: (base) => ({ ...base, minHeight: '40px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectFormAttribute()}
      </div>
    );
  }
}

SelectFormAttribute.propTypes = {
  form_attributes: PropTypes.array,
};

export default SelectFormAttribute;
