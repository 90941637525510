import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';

class SelectProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      profile: props.profile,
      isLoading: true,
    };
  }

  componentDidMount() {
  }

  renderSelectProfile() {
    const account = this.state.account;
    const profile = this.state.profile;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        profile: value,
      });
      if (value) {
        document.getElementById('profile_id').value = value.id;
      } else {
        document.getElementById('profile_id').value = null;
      }
    };

    const loadOptions = (value) => {
      return fetch(`/accounts/${account.id}/profiles/select?search=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      )
      .then(response => response.json());
    };

    const customOption = (profile) => {
      return (
        <div>
          <div className="d-flex align-items-center">
            <div className="mr-1">
              {profile["title"]}
            </div>
            {profile["badge"] && <div className="ml-auto small">
              {profile["badge"]}
            </div>}
          </div>
          {profile["description"] && <div className="small select-description">
            {profile["description"]}
          </div>}
        </div>
      );
    }

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder={"Select a profile..."}
        value={profile}
        getOptionLabel={c => customOption(c)}
        getOptionValue={c => c.id}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isClearable
        styles={{
          singleValue: (base) => ({ ...base, width: '96%' }),
          valueContainer: (base) => ({ ...base, minHeight: '44px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectProfile()}
      </div>
    );
  }
}

SelectProfile.propTypes = {
  account: PropTypes.object,
  profile: PropTypes.object,
};
export default SelectProfile;
