import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

class SelectContact extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      contact: props.contact,
      isLoading: true,
    };
  }

  componentDidMount() {
  }

  renderSelectContact() {
    const account = this.state.account;
    const contact = this.state.contact;

    let timerId = null;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        contact: value,
      });
      if (value) {
        document.getElementById('contact_id').value = value.id;
      } else {
        document.getElementById('contact_id').value = null;
      }
    };

    const loadOptions = (value) => {
      return fetch(`/accounts/${account.id}/contacts/select?search=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      )
      .then(response => response.json());
    };

    const customOption = (contact) => {
      return (
        <div>
          <div className="d-flex align-items-center">
            <div className="mr-1">
              {contact["title"]}
            </div>
            {contact["badge"] && <div className="ml-auto small">
              {contact["badge"]}
            </div>}
          </div>
          {contact["description"] && <div className="small select-description">
            {contact["description"]}
          </div>}
        </div>
      );
    }

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder={"Select a contact..."}
        value={contact}
        getOptionLabel={c => customOption(c)}
        getOptionValue={c => c.id}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isClearable
        styles={{
          singleValue: (base) => ({ ...base, width: '96%' }),
          valueContainer: (base) => ({ ...base, minHeight: '44px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectContact()}
      </div>
    );
  }
}

SelectContact.propTypes = {
  account: PropTypes.object,
  contact: PropTypes.object,
};
export default SelectContact;
