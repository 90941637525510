import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import Map, { Marker } from "react-map-gl";
import mapboxgl from "mapbox-gl";

const SimpleMap = (params) => {
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const [locations, setLocations] = useState(null);
  const [mapViewState, setMapViewState] = useState(null);

  useEffect(() => {
    setLocations(params["locations"]);
    changeMapViewState(params["locations"][0]["longitude"], params["locations"][0]["latitude"]);
  }, []);

  function changeMapViewState(lng, lat) {
    setMapViewState({
      longitude: lng,
      latitude: lat,
      zoom: 12,
    });
  }

  function renderMarkers() {
    const markers = locations.map((location, index) => {
      return <Marker longitude={location.longitude} latitude={location.latitude} anchor="bottom" />;
    });
    return (
      {markers}
    );
  }

  return (
    <div>
      <div className="form-map-container">
        {(locations) && <Map
          {...mapViewState}
          scrollZoom={false}
          style={{width: "100%", height: 400}}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken="pk.eyJ1IjoiZGV2c3BhY2VvbmUiLCJhIjoiY2tyMXI0N3U4MjRsbTJ2cWFlOWR1OGM0NCJ9.hBD-m-8DIlw9dMKYpAs-zQ"
        >
          {locations.map((location, index) => <Marker key={index} longitude={location.longitude} latitude={location.latitude} anchor="bottom" />)}
        }
        </Map>}
      </div>
    </div>
  );
};

SimpleMap.propTypes = {
  google_place_result: PropTypes.string
};

export default SimpleMap;
