import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByLatLng } from 'react-google-places-autocomplete';
import Map, { Marker, NavigationControl } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import { Form, Button, Row, Col } from 'react-bootstrap';

const SelectAddress = (params) => {
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

  const [address, setAddress] = useState(null);
  const [google_address, setGoogleAddress] = useState(null);
  const [placeId, setPlaceId] = useState(null);
  const [placeResult, setPlaceResult] = useState(null);
  const [geocodeResult, setGeocodeResult] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [mapViewState, setMapViewState] = useState(null);
  const model_name = params["model_name"] || "";
  const model_prefix = params["model_prefix"] || "";

  useEffect(() => {
    setAddress(params["address"]);
    setGoogleAddress(params["google_address"]);
    setPlaceId(params["google_place_id"]);
    setPlaceResult(params["google_place_result"]);
    setGeocodeResult(params["google_geocode_result"]);
    setLongitude(params["longitude"]);
    setLatitude(params["latitude"]);
    changeMapViewState(params["longitude"], params["latitude"]);
  }, []);

  function changeMapViewState(lng, lat) {
    setMapViewState({
      longitude: lng,
      latitude: lat,
      zoom: 12,
    });
  };

  function getGeocodeByLatLng(lng, lat, callback) {
    document.getElementById("submit").setAttribute("disabled","disabled");
    geocodeByLatLng({lng: lng, lat: lat})
      .then(geocode => {
        callback(geocode);
        document.getElementById("submit").removeAttribute("disabled");
      })
      .catch(error => console.error(error));
  };

  function getGeocodeByPlaceId(place_id, callback) {
    document.getElementById("submit").setAttribute("disabled","disabled");
    geocodeByPlaceId(place_id)
      .then(geocode => {
        callback(geocode);
        document.getElementById("submit").removeAttribute("disabled");
      })
      .catch(error => console.error(error));
  };

  function handlePlacesChange(place_result) {
    const callback = geocode => {
      if (Array.isArray(geocode) && geocode.length > 0) {
        setGoogleAddress(place_result["label"]);
        setPlaceId(geocode[0]["place_id"]);
        setGeocodeResult(geocode);
        setLongitude(geocode[0]["geometry"]["location"].lng());
        setLatitude(geocode[0]["geometry"]["location"].lat());
        changeMapViewState(geocode[0]["geometry"]["location"].lng(), geocode[0]["geometry"]["location"].lat());
      }
    };
    setPlaceResult(place_result);
    if (place_result) {
      getGeocodeByPlaceId(place_result["value"]["place_id"], callback);
    } else {
      setGoogleAddress(null);
      setPlaceId(null);
      setGeocodeResult(null);
      setLongitude(null);
      setLatitude(null);
    };
  };

  function handleAddressChange(event) {
    setAddress(event.target.value);
  }

  function handleLongitudeChange(event) {
    if (event.target.value >= -180 && event.target.value <= 180) {
      setLongitude(event.target.value);
    }
  };

  function handleLatitudeChange(event) {
    if (event.target.value >= -90 && event.target.value <= 90) {
      setLatitude(event.target.value);
    }
  };

  function handleLngLatChange() {
    const lng = parseFloat(longitude);
    const lat = parseFloat(latitude);
    const callback = geocode => {
      if (Array.isArray(geocode) && geocode.length > 0) {
        setGoogleAddress(geocode[0]["formatted_address"]);
        setPlaceId(geocode[0]["place_id"]);
        setPlaceResult({"label": geocode[0]["formatted_address"], "value": {"place_id": geocode[0]["place_id"]}});
        setGeocodeResult(geocode);
        changeMapViewState(geocode[0]["geometry"]["location"].lng(), geocode[0]["geometry"]["location"].lat());
      }
    };
    if ((lng >= -180 && lng <= 180) && (lat >= -90 && lat <= 90)) {
      getGeocodeByLatLng(lng, lat, callback);
    }
  };

  function handleOnMarkerDragEnd(event) {
    const callback = geocode => {
      if (Array.isArray(geocode) && geocode.length > 0) {
        setGoogleAddress(geocode[0]["formatted_address"]);
        setPlaceId(geocode[0]["place_id"]);
        setPlaceResult({"label": geocode[0]["formatted_address"], "value": {"place_id": geocode[0]["place_id"]}});
        setGeocodeResult(geocode);
        setLongitude(geocode[0]["geometry"]["location"].lng());
        setLatitude(geocode[0]["geometry"]["location"].lat());
        changeMapViewState(geocode[0]["geometry"]["location"].lng(), geocode[0]["geometry"]["location"].lat());
      }
    };
    if (event.lngLat) {
      getGeocodeByLatLng(event.lngLat.lng, event.lngLat.lat, callback);
    }
  };

  return (
    <div>
      <Form.Group controlId="formAddress" className="form-group">
        <Form.Label>Address</Form.Label>
        <Form.Control
          type="text"
          value={address || ""}
          onChange={handleAddressChange}
          name={`${model_name}[${model_prefix}address]`}
        />
      </Form.Group>
      <Form.Group controlId="formLatitude" className="form-group">
        <Form.Label>Latitude</Form.Label>
        <Form.Control
          type="number"
          value={latitude || ""}
          min="-90"
          max="90"
          step="0.000000000000001"
          onChange={handleLatitudeChange}
          onBlur={handleLngLatChange}
          name={`${model_name}[${model_prefix}latitude]`}
        />
      </Form.Group>
      <Form.Group controlId="formLongitude" className="form-group">
        <Form.Label>Longitude</Form.Label>
        <Form.Control
          type="number"
          value={longitude || ""}
          min="-180"
          max="180"
          step="0.000000000000001"
          onChange={handleLongitudeChange}
          onBlur={handleLngLatChange}
          name={`${model_name}[${model_prefix}longitude]`}
        />
      </Form.Group>
      <Form.Group controlId="formAddress" className="form-group">
        <Form.Label>Google address</Form.Label>
        <GooglePlacesAutocomplete
          apiKey="AIzaSyARZLY32Fe75D53Zx0nb5C2ioCjbhTcdmc"
          debounce="3"
          selectProps={{
            value: placeResult,
            onChange: handlePlacesChange,
            isClearable: true,
            placeholder: 'Search address...',
            styles: {
              valueContainer: (base) => ({ ...base, minHeight: '40px'}),
            },
          }}
        />
      </Form.Group>
      <div className="form-map-container">
        {(latitude && longitude) && <Map
          {...mapViewState}
          scrollZoom={false}
          onMove={evt => setMapViewState(evt.mapViewState)}
          style={{width: "100%", height: 400}}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxAccessToken="pk.eyJ1IjoiZGV2c3BhY2VvbmUiLCJhIjoiY2tyMXI0N3U4MjRsbTJ2cWFlOWR1OGM0NCJ9.hBD-m-8DIlw9dMKYpAs-zQ"
        >
          <NavigationControl />
          <Marker longitude={longitude} latitude={latitude} anchor="bottom" draggable={true} onDragEnd={handleOnMarkerDragEnd} />
        </Map>}
      </div>
      <div>
        <Form.Control type="hidden" name={`${model_name}[${model_prefix}google_address]`} value={google_address || ""} />
        <Form.Control type="hidden" name={`${model_name}[${model_prefix}google_place_id]`} value={placeId || ""} />
        <Form.Control type="hidden" name={`${model_name}[${model_prefix}google_place_result]`} value={JSON.stringify(placeResult) || ""} />
        <Form.Control type="hidden" name={`${model_name}[${model_prefix}google_geocode_result]`} value={JSON.stringify(geocodeResult) || ""} />
      </div>
    </div>
  );
};

SelectAddress.propTypes = {
  google_place_result: PropTypes.object
};

export default SelectAddress;
