import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';

class SelectTags extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      tagable_type: props.tagable_type,
      tags: props.tags,
      isLoading: true,
    };
  }

  componentDidMount() {
  }

  renderSelectTags() {
    const account = this.state.account;
    const tagable_type = this.state.tagable_type;
    const tags = this.state.tags;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        tags: value
      });
      let result = value.map((tag) => tag.id);
      document.getElementById('tag_ids').value = JSON.stringify(result);
    };

    const loadOptions = (value) => {
      return fetch(`/accounts/${account.id}/tags/select?search=${value}&tagable_type=${tagable_type}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      )
      .then(response => response.json());
    };

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        isMulti
        placeholder={"Select tags..."}
        value={tags}
        getOptionLabel={c => c.title}
        getOptionValue={c => c.id}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        styles={{
          valueContainer: (base) => ({ ...base, minHeight: '40px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectTags()}
      </div>
    );
  }
}

SelectTags.propTypes = {
  account: PropTypes.object,
  tagable_type: PropTypes.string,
  tags: PropTypes.array,
};

export default SelectTags;
