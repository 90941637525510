import React from "react"
import PropTypes from "prop-types"
import AsyncSelect from 'react-select/async';

class SelectLocation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: props.account,
      location: props.location,
      isLoading: true,
    };
  }

  componentDidMount() {
  }

  renderSelectLocation() {
    const account = this.state.account;
    const location = this.state.location;

    const handleInputChange = value => {
    };

    const handleChange = value => {
      this.setState({
        location: value,
      });
      if (value) {
        document.getElementById('location_id').value = value.id;
      } else {
        document.getElementById('location_id').value = null;
      }
    };

    const loadOptions = (value) => {
      return fetch(`/accounts/${account.id}/locations/select?search=${value}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Accept": "application/json"
          }
        }
      )
      .then(response => response.json());
    };

    const customOption = (location) => {
      return (
        <div>
          <div className="d-flex align-items-center">
            <div className="mr-1">
              {location["title"]}
            </div>
            {location["badge"] && <div className="ml-auto small">
              {location["badge"]}
            </div>}
          </div>
          {location["description"] && <div className="small select-description">
            {location["description"]}
          </div>}
        </div>
      );
    }

    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        placeholder={"Select a location..."}
        value={location}
        getOptionLabel={c => customOption(c)}
        getOptionValue={c => c.id}
        loadOptions={loadOptions}
        onInputChange={handleInputChange}
        onChange={handleChange}
        isClearable
        styles={{
          singleValue: (base) => ({ ...base, width: '96%' }),
          valueContainer: (base) => ({ ...base, minHeight: '44px'}),
        }}
      />
    );
  }

  render () {
    return (
      <div>
        {this.renderSelectLocation()}
      </div>
    );
  }
}

SelectLocation.propTypes = {
  account: PropTypes.object,
  location: PropTypes.object,
};
export default SelectLocation;
